import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide26/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide26/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide26/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide26/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide26/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo implementar la inteligencia comercial en telemarketing?
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La inteligencia comercial se basa en el procesamiento de grandes volúmenes
      de datos provenientes de diversas fuentes, como ventas o marketing. Estos
      datos se transforman en informes que permiten tomar decisiones informadas
      y estratégicas.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Contar con información oportuna y precisa es crucial para mantenerse
    relevante y ágil en el mercado. La inteligencia comercial no solo facilita
    el análisis retrospectivo del rendimiento empresarial, sino que también abre
    la puerta a la predicción y la planificación proactiva.
    <br /> <br />
    Con la evolución de la tecnología, la inteligencia comercial ha dado paso a
    nuevas tendencias, como el análisis predictivo, el aprendizaje automático y
    la inteligencia artificial.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Definición de objetivos comerciales.",
      },
      {
        key: 2,
        text: "Recopilación y análisis de datos.",
      },
      {
        key: 3,
        text: "Segmentación de la base de clientes.",
      },
      {
        key: 4,
        text: "Desarrollo de perfiles de cliente.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
